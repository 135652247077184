import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import MarkdownRenderers from '../../utils/LegalMarkdownRenderers';

const TermsAndConditions: FC = () => {
  const { t } = useTranslation('legal-terms-and-conditions');
  const termsAndConditions = t('default');

  return (
    <>
      <div className="mt-2 pb-8">
        <ReactMarkdown components={MarkdownRenderers}>{termsAndConditions}</ReactMarkdown>
      </div>
    </>
  );
};

export default TermsAndConditions;
